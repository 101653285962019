<template>
  <Modal v-if="modal">
    <template v-slot:headline>{{ title }}</template>
    <template v-slot:body>
      <FormRow :required="true">
        <template v-slot:label>グループ名</template>
        <template v-slot:content>
          <div class="form-content-row">
            <input
              v-trim
              v-maxlength
              maxlength="20"
              v-model="form.name"
              :class="{ 'form-control': true, 'is-error': errors.name }"
              type="text"
              name="name"
            />
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>メールアドレス / ID</template>
        <template v-slot:content>
          <div class="form-content-row">
            <input
              v-trim
              v-maxlength
              maxlength="50"
              v-model="form.email"
              :class="{ 'form-control': true, 'is-error': errors.email }"
              type="text"
              name="email"
              v-email
            />
          </div>
        </template>
      </FormRow>
      <FormRow :required="!isEdit">
        <template v-slot:label>初回パスワード</template>
        <template v-slot:labelNote><span class="text-note">（8文字以上で半角英数字および記号（/ \ ¥ を除く）を全て組み合わせ) </span></template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="form-password">
              <input
                v-trim
                v-model="form.password"
                :class="{ 'form-control form-password-input': true, 'is-error': errors.password }"
                :type="pwdVisible ? 'text' : 'password'"
                name="password"
              />
              <label class="form-check form-password-toggle">
                <input class="form-check-input" type="checkbox" value="" name="" v-model="pwdVisible" />
                <i class="aikon form-password-icon"></i>
              </label>
            </div>
          </div>
        </template>
      </FormRow>
      <UserMailSetting :formData="form" @updateData="updateForm" :hasReceptionStoreEmailFlag="true"/>
      <TableActivites
        v-if="isEdit"
        customClass="panel-group"
        :activities="userActivities"
      />
    </template>
    <template v-slot:footer>
      <ul class="listGrid justify-content-end">
        <li class="listGrid-item">
          <button class="btn btn-white" type="button" @click="closeModal">キャンセル</button>
        </li>
        <li class="listGrid-item">
          <ActionButton class="btn btn-main" :handle-submit="handleSubmit" :button-text="buttonText" />
        </li>
      </ul>
    </template>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, email, maxLength } from 'vuelidate/lib/validators';
//helper
import { isAlphanumSpecialWithMinLength } from '@/helpers/validators';
//mixin
import password from '@/mixins/plugin/password';
import error from '@/mixins/plugin/error';
//component
import Modal from '@/components/Modal.vue';
import FormRow from '@/components/FormRow.vue';
import TableActivites from '@/components/TableActivites.vue';
import userMailSetting from '@/mixins/module/userMailSetting';
import UserMailSetting from '@/components/UserMailSetting.vue';

export default {
  name: 'GroupForm',
  data() {
    return {
      form: {},
      modal: false,
      isEdit: false,
      id: null,
      groupId: null,
    }
  },
  mixins: [error, password, userMailSetting],
  components: {
    Modal,
    FormRow,
    TableActivites,
    UserMailSetting,
  },
  computed: {
    ...mapGetters({
      userDetail: 'settingUser/userDetail',
    }),
    title() {
     return this.isEdit ? "グループ情報編集" : "グループの登録"
    },
    buttonText() {
     return this.isEdit ? "編集する" : "登録する"
    }
  },
  validations() {
    const form = {
      name: { required, maxLength: maxLength(20) },
      email: { required, email, maxLength: maxLength(50) },
    }
    if (this.isEdit)
      form.password = { alphanumSpecialWithMinLength: isAlphanumSpecialWithMinLength(8) }
    else
      form.password = { required, alphanumSpecialWithMinLength: isAlphanumSpecialWithMinLength(8) }
    return { form };
  },
  methods: {
    async showModal(isEdit, item, modulePath) {
      this.modal = true;
      this.resetForm();
      this.isEdit = isEdit;
      if (this.isEdit) {
        this.id = item?.userId;
        this.groupId = item?.id;
        await this.$store.dispatch(modulePath, this.id);
        this.form = {
          ...this.form,
          ...this.userDetail,
          name: this.userDetail?.nameSei
        };
        this.getActivities();
      }
    },
    closeModal() {
      this.modal = false;
      this.$message.reset();
      this.$store.dispatch('common/resetErrorFields');
    },
    resetForm() {
      this.form = {
        receptionEmailFlag: 1,
        authEmailFlag: 0,
        receptionStoreEmailFlag: 1,
      };
      this.isEdit = false;
    },
    updateForm(data) {
      this.form = { ...this.form, ...data };
    },
    async handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.form, 'group');
      } else {
        let data = {
          ...this.form,
          id: this.groupId,
          receptionEmailFlag: this.form.receptionEmailFlag ? 1 : 0,
          authEmailFlag: this.form.authEmailFlag ? 1 : 0,
          receptionStoreEmailFlag: this.form.receptionStoreEmailFlag ? 1 : 0,
        }
        let result;
        if (this.isEdit) {
          result = await this.$store.dispatch('group/updateGroup', data);
          if (result) {
            this.closeModal();
            this.$emit('update-list', this.isEdit);
            this.$message.updated('groupInfor');
          }
        } else {
          delete data.id;
          result = await this.$store.dispatch('group/createGroup', data);
          if (result) {
            this.closeModal();
            this.$emit('update-list');
            this.$message.created('group');
          }
        }
      }
    },
  },
}
</script>
