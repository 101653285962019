<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <TableWithCheckbox
                    :labels="labels"
                    :data-table="dataTable"
                  />
                </div>
              </template>
              <template v-slot:footer>
                 <PaginationLog
                  ref="pagination"
                  :listLength="groupCount"
                  :modulePath="modulePath"
                  :searchConditions="searchConditions"
                />
              </template>
            </Panel>
          </div>
        </div>
        <GroupForm
          ref="form"
          @update-list="getGroupList"
        />
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item pos-end">
            <button class="btn btn-main" type="button" @click="showForm">グループ登録</button>
          </li>
        </ul>
      </template>
    </PageFooter>
    <Modal @close="closeDelete" v-if="deleteModal">
      <template v-slot:headline>削除</template>
      <template v-slot:body>
        <p class="description text-align-center">
          グループを削除するとグループのアカウントで管理画面にログインできなくなります。<br />
          グループを削除しますか？<br />
          ※グループ内の店舗が削除されることはありません
        </p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button class="btn btn-lg btn-white" type="button" @click="closeDelete">キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-lg btn-red" :handle-submit="handleDelete" button-text="削除する" />
          </li>
        </ul>
      </template>
    </Modal>
    <!--[/footer]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
//mixin
import nav from '@/mixins/nav/shop';
import password from '@/mixins/plugin/password';
//component
import Modal from '@/components/Modal.vue';
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import GroupForm from './Form.vue';

export default {
  name: 'EventShopGroup',
  data: function() {
    return {
      pageName: '店舗管理',
      deleteModal: false,
      modulePath: 'group/getGroupList',
      deleteId: null,
      labels: [
        { key: 'name', name: 'グループ名' },
        { key: 'count', name: '店舗数' },
        { isButtonColumn: true , key: 'actionButton', name: 'グループ情報' },
        { isButtonColumn: true , key: 'buttonLinks', name: 'アクション' },
      ],
    };
  },
  mixins: [nav, password],
  components: {
    Modal,
    TableWithCheckbox,
    PaginationLog,
    GroupForm,
  },
  computed: {
    ...mapGetters({
      groupList: 'group/groupList',
      groupCount: 'group/groupCount',
      isClientChild: 'auth/isClientChild',
    }),
    searchConditions() {
      return { allowChildUser: this.isClientChild ? 0 : 1 };
    },
    dataTable() {
      return this.groupList.map(item => {
        return {
          ...item,
          actionButton: [
            { content: 'グループ情報編集', class: 'btn btn-sm btn-bd-main', function: () => this.showForm(item) },
            { content: 'グループ削除', class: 'btn btn-sm btn-bd-red', function: () => this.showDelete(item.id) },
          ],
          buttonLinks: [
            { content: 'グループに追加', class: 'btn-main', routeName: 'EventShopGroupAdd', params: { id: item.id } },
            { content: 'グループから削除', class: 'btn-red router-link-active', routeName: 'EventShopGroupDelete', params: { id: item.id } },
          ],
        }
      })
    },
  },
  methods: {
    showForm(item) {
      const isEdit = !!item?.id
      this.$refs.form.showModal(isEdit, item, 'settingUser/getUserDetail');
    },
    showDelete(id) {
      this.deleteModal = true;
      this.deleteId = id;
    },
    closeDelete() {
      this.deleteModal = false;
    },
    async handleDelete() {
      const result = await this.$store.dispatch('group/deleteGroup', { id: this.deleteId });
      this.closeDelete();
      if (result) {
        this.getGroupList();
        this.$message.deleted('group');
      }
    },
    getGroupList(isEdit) {
      if (isEdit)
        this.$refs.pagination.getPaginationList();
      else
        this.$refs.pagination.resetPagination();
    },
  },
};
</script>
